
  import Vue from 'vue';
  import image from '@/assets/svg/how.svg';

  export default Vue.extend({
    name: 'es-pro-home-how-it-works',
    data: () => ({
      image,
    }),
  });
