<template>
  <div class="content">
    <div class="hero pro-hero">
      <div class="opacity-mask">
        <div class="container">
          <div class="row pt-6 px-6 pro-hero-mobile-padding">
            <div class="col-md-7 col-xl-8">
              <div class="text-center text-content mt-4 d-flex align-items-start flex-column justify-content-center">
                <img src="@/assets/jpg/lucreaza_cu_noi.jpg" class="pro-hero-mobile">
                <h1 class="text-left">{{ $t('views.pro_home.page_title') }}</h1>
                <p class="my-2 text-left">{{ $t('views.pro_home.page_subtitle') }}</p>
                <button
                  class="btn btn-pink btn-pill mt-4 px-6"
                  @click="onSubmit"
                >
                  {{ $t('views.pro_home.apply_now') }}
                </button>
              </div>
            </div>
          </div>
          <!-- /row -->
        </div>
      </div>
      <div class="wave hero pro" />
    </div>
    <!-- /hero_single -->
    <es-work-with-us />
    <es-more-details />
    <es-how-it-works />
    <div class="text-center text-content m-4 p-4 d-flex align-items-center flex-column justify-content-center custom-pro">
      <div class="row">
        <div class="col-md-12 col-lg-8 mx-auto">
          <h3 class="text-center">{{ $t('views.pro_home.footer_message') }}</h3>
          <button
            class="btn btn-pink btn-pill mt-4 px-6"
            @click="onSubmit"
          >
            {{ $t('views.pro_home.apply_now') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import HowItWorks from './HowItWorks.vue';
  import MoreDetails from './MoreDetails.vue';
  import WorkWithUs from './WorkWithUs.vue';

  export default Vue.extend({
    name: 'es-pro-home',

    /* eslint-disable */
    metaInfo: {
      title: 'Castiga mai multi bani, pe termenii tai cu Ease',
      titleTemplate: '',
      description: 'Ofera servicii de masaj, beauty sau fitness prin Ease si castigi de pana la 2 ori mai mult decat standardul in industrie.',
      meta: [
        { name: 'description', content: 'Ofera servicii de masaj, beauty sau fitness prin Ease si castigi de pana la 2 ori mai mult decat standardul in industrie' },
        { name: 'og:title', content: 'Castiga mai multi bani, pe termenii tai cu Ease' },
        { name: 'og:description', content: 'Ofera servicii de masaj, beauty sau fitness prin Ease si castigi de pana la 2 ori mai mult decat standardul in industrie' },
        { name: 'og:image', content: require('@/assets/jpg/fb-thumb_lucreaza_cu_noi.jpg'), },
      ],
    },

    components: {
      'es-how-it-works': HowItWorks,
      'es-more-details': MoreDetails,
      'es-work-with-us': WorkWithUs,
    },

    mounted() {
      window.initEase();
    },

    created() {
      this.$zendesk.load('4591939b-c8e2-4d8c-b9db-bb9e1b531846');
    },

    methods: {
      onSubmit() {
        this.$root.$emit('on-show-elite-register');
      },
    },
  });
</script>
